import React from 'react';
import {withTranslate, Loading} from 'react-admin';
import {Table, TableBody, TableCell, TableContainer, TableRow, Paper, Tabs, Tab, Typography, Box} from '@material-ui/core';

import '../../assets/css/cart.css';
import '../../assets/css/order.css';
import '../../helper/Utils';
import WebPosUtils from "../../helper/Utils";
import PaymentList from "../sales/PaymentList";
import RefundsList from "../sales/RefundsList";
import PropTypes from 'prop-types';
import {Button, Row, Col, Card} from 'react-bootstrap';
import {Print as PrintIcon, CardGiftcard as CardGiftcardIcon, AssignmentReturned as AssignmentReturnedIcon, Delete as DeleteIcon, Receipt as ReceiptIcon} from '@material-ui/icons';


function TabPanel(props) {
    const { children, value, index, ...other } = props;

    return (
        <Typography
            component="div"
            role="tabpanel"
            hidden={value !== index}
            id={`simple-tabpanel-${index}`}
            aria-labelledby={`simple-tab-${index}`}
            {...other}
        >
            {value === index && <Box p={3}>{children}</Box>}
        </Typography>
    );
}

TabPanel.propTypes = {
    children: PropTypes.node,
    index: PropTypes.any.isRequired,
    value: PropTypes.any.isRequired,
};

function a11yProps(index) {
    return {
        id: `simple-tab-${index}`,
        'aria-controls': `simple-tabpanel-${index}`,
    };
}


class Quote extends React.Component {

    constructor(props) {
        super(props);
        this.state = {tab_active:0}
        this.changeTab = this.changeTab.bind(this);
        this.candAddNewPayment = this.candAddNewPayment.bind(this);

    }

    canShowSummary() {
        return this.props.order;
    }
    changeTab(event, newValue){
        this.setState({tab_active:newValue});

    }
    candAddNewPayment(){

        return this.props.record && this.props.record.total_due > 0 && this.props.record.state !== 'canceled' && this.props.checkout !== "1" && this.props.record.terminal!="Online";
    }

    render() {

        const {translate} = this.props;
        if(this.props.order === "1"){
            if(!this.props.record || !this.props.record.items){
                return (<Card><Card.Body>   <Loading loadingPrimary=""  loadingSecondary="app.core.loading1"/></Card.Body></Card>);
            }
        }

        return (

            <div className="cart">
                {this.props.order === "1" && this.props.checkout !== "1" &&(
                <Tabs value={this.state.tab_active} onChange={this.changeTab}>
                    <Tab label="Pedido"   {...a11yProps(0)}/>
                    {WebPosUtils.getPermission("returns") && (
                    <Tab label="Devoluciones"   {...a11yProps(1)}/>
                    )}

                </Tabs>
                )}
                <TabPanel value={this.state.tab_active} index={0}>

                {this.canShowSummary() && (
                    <Card className="order_header">
                        <Card.Header>
                            <Row>
                                <Col xs="auto">
                            {translate('app.quote.order_header')} #{this.props.record.increment_id} {this.props.checkout !== "1" && WebPosUtils.getPermission("returns") && this.props.record.can_refund === 1 && (<Button size="sm" variant="danger" onClick={() => {
                            this.props.toggleRefund(true, this.props.record)
                        }}><AssignmentReturnedIcon /> {translate('app.quote.refund')}</Button>)}
                                    {this.props.checkout !== "1" && this.props.record.can_cancel === 1 && this.props.record.state !== 'canceled' && (<Button size="sm" variant="warning" onClick={() => {
                                        this.props.toggleVoid(true)
                                    }}><DeleteIcon /> {translate('app.quote.void_order')}</Button>)}
                                    {this.props.checkout !== "1" && this.props.record.webpos_booking === 1  && WebPosUtils.getSettings().booking && (<Button size="sm" variant="secondary" className="ml-10" onClick={() => {
                                        this.props.toggleBooking(true)
                                    }}><ReceiptIcon /> {translate('app.quote.submit_booking')}</Button>)}
                                </Col>

                            {!WebPosUtils.getPermission("hide_print_button") && this.props.record.terminal !=="Online" && (
                        <Col className="text-end">
                            {WebPosUtils.getPermission("gift_ticket") && (
                                <Button size="sm" variant="dark" onClick={() => {

                                    this.props.printGiftTicketAction(this.props.record.id)
                                }}
                                title={translate("app.quote.print_gift_ticket")}><CardGiftcardIcon />
                                </Button>
                            )}
                            &nbsp;
                            <Button size="sm" variant="primary" title={translate("app.quote.print_order_ticket")} onClick={() => {
                            this.props.printAction(this.props.record.id)
                        }}
                        ><PrintIcon />
                        </Button>
                        </Col>
                            )}
                            </Row>
                        </Card.Header>
                        <Card.Body>
                            <Row>
                                <Col xs="auto">
                            {this.props.record.terminal ? (
                                <strong>{translate("app.quote.terminal")}:{this.props.record.terminal}</strong>
                                ):(
                                <strong>{translate("app.quote.online_order")}</strong>
                                )
                            }
                                </Col>
                                <Col xs="auto">
                                    <strong>{translate('app.quote.created_at')}</strong>: {WebPosUtils.formatDateFromMysql(this.props.record.created_at)}</Col>
                                <Col xs="auto">
                                    <strong>{translate('app.quote.customer_name')}</strong>: {this.props.record.customer_name}</Col>
                                <Col xs="auto">
                                    <strong>{translate('app.quote.order_status')}</strong>: {this.props.record.status}
                                    </Col>
                            </Row>
                        </Card.Body>
                    </Card>)}
                {this.props.record.payments && this.props.record.payments.length > 0 && (

                    <PaymentList record={this.props.record} order={this.props.order} can_add={this.candAddNewPayment()} togglePaymentList={this.props.togglePaymentList}/>
                )
                }

                <Card className="">
                    <Card.Header>{translate('app.quote.items')}</Card.Header>
                    <div>
                        <TableContainer component={Paper}>
                            <Table aria-label="simple table cart">
                                <TableBody>
                                    {this.props.record && this.props.record.items && this.props.record.items.map((row, index) => (
                                        <TableRow key={index}>
                                            <TableCell align="left" className="name_td">{row.name}
                                                <ul className="configurable_options">
                                                    {row.type === "configurable" && row.selected_values && row.selected_values.map((att, index2) => (
                                                        <li key={index2}>
                                                            <strong>{att.label}</strong> : {att.value_label}
                                                        </li>
                                                    ))}
                                                </ul>
                                            </TableCell>

                                            <TableCell align="right"
                                                       className="price_td">{WebPosUtils.formatCurrency(row.price)}

                                            </TableCell>
                                            <TableCell align="right" className="qty_td">x{row.qty}
                                            {row.qty_refunded > 0 && (
                                                    <div>
                                                    {translate("app.quote.qty_refunded")}:x{row.qty_refunded}
                                                    </div>
                                                )}
                                            </TableCell>
                                            <TableCell align="right"
                                                       className="qty_td">{WebPosUtils.formatCurrency(row.qty * row.price)}</TableCell>


                                        </TableRow>
                                    ))}
                                </TableBody>
                            </Table>
                        </TableContainer>
                    </div>
                </Card>
                <div>
                    {this.props.record &&

                    <div className="row">
                        <div className="col-xl-8 offset-xl-4 col-lg-12">
                            <TableContainer component={Paper}>

                                <Table className="cart" size="small">
                                    <TableBody>
                                        <TableRow>
                                            <TableCell align="right"
                                                       className="subtotal_label">{translate('app.quote.subtotal')}</TableCell>
                                            <TableCell
                                                align="right"> {WebPosUtils.formatCurrency(this.props.record.subtotal)}</TableCell>
                                        </TableRow>
                                        {this.props.record.shipping_amount >0 && (
                                            <TableRow>
                                                <TableCell align="right"
                                                           className="subtotal_label">{translate('app.quote.shipping_amount')}</TableCell>
                                                <TableCell
                                                    align="right"> {WebPosUtils.formatCurrency(this.props.record.shipping_amount)}</TableCell>
                                            </TableRow>
                                        )}
                                        {this.props.record.discount_amount !== 0 && (
                                            <TableRow>
                                                <TableCell align="right"
                                                           className="subtotal_label">{translate('app.quote.discount')}</TableCell>
                                                <TableCell
                                                    align="right"> {WebPosUtils.formatCurrency(this.props.record.discount_amount)}</TableCell>
                                            </TableRow>
                                        )}
                                        <TableRow>
                                            <TableCell align="right"
                                                       className="subtotal_label">{translate('app.quote.tax')}</TableCell>
                                            <TableCell
                                                align="right"> {WebPosUtils.formatCurrency(this.props.record.tax)}</TableCell>
                                        </TableRow>
                                        <TableRow>
                                            <TableCell align="right"
                                                       className="subtotal_label">{translate('app.quote.grand_total')}</TableCell>
                                            <TableCell
                                                align="right"> {WebPosUtils.formatCurrency(this.props.record.grand_total)}</TableCell>
                                        </TableRow>
                                        {this.props.record.total_due > 0 && (
                                            <TableRow>
                                                <TableCell align="right"
                                                           className="subtotal_label">{translate('app.quote.amount_due')} </TableCell>
                                                <TableCell
                                                    align="right">
                                                    <div
                                                        className="alert-danger">{WebPosUtils.formatCurrency(this.props.record.total_due)}</div>
                                                </TableCell>
                                            </TableRow>
                                        )}
                                        {this.props.record.total_refunded > 0 && (
                                            <TableRow>
                                                <TableCell align="right"
                                                           className="subtotal_label">{translate('app.quote.amount_refund')} </TableCell>
                                                <TableCell
                                                    align="right">
                                                    <div
                                                        className="alert-info">{WebPosUtils.formatCurrency(this.props.record.total_refunded)}</div>
                                                </TableCell>
                                            </TableRow>
                                        )}
                                    </TableBody>
                                </Table>
                            </TableContainer>
                        </div>
                    </div>
                    }

                    {
                        this.props.children
                    }
                </div>
                </TabPanel>
                <TabPanel value={this.state.tab_active} index={1}>
                    {this.props.record && this.props.record.refunds && this.props.record.refunds.map((refund, index) => (
                        <RefundsList refund={refund} key={index} printRefundAction={this.props.printRefundAction}/>
                    ))}
                </TabPanel>
            </div>

        );
    }

}

export default withTranslate(Quote);